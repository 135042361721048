//to handle state
const state = {
    purchase_order_detail: [],
}

//to handle state
const getters = {}

//to handle actions
const actions = {

    mLoadAddPurchaseOrderDetail(context,product) {
        let validate = true;
        let detail = context.state.purchase_order_detail;
        for (let index = 0; index < detail.length; index++) {
            if (detail[index].id_product == product.id_product) {
                detail[index].quantity = parseFloat(detail[index].quantity) + parseFloat(product.quantity);
                detail[index].quantity = parseFloat(detail[index].quantity).toFixed(2);
                validate = false;
                break;
            }
        }
        if (validate) {
            context.commit('mAddPurchaseOrderDetail',product);
        }
        
    },

    mLoadEditPurchaseOrderDetail(context,index){
        let detail = context.state.purchase_order_detail;
        detail[index].quantity = detail[index].quantity.length == 0 ? 0 : parseFloat(detail[index].quantity);
        detail[index].quantity = parseFloat(detail[index].quantity).toFixed(2);
    },

    mLoadDeletePurchaseOrderDetail(context,index){
        context.commit('mDeletePurchaseOrderDetail',index);
    },

    mLoadResetPurchaseOrderDetail(context){
        context.commit('mResertPurchaseOrderDetail');
    },


   

}

//to handle mutations
const mutations = {

    mAddPurchaseOrderDetail(state, detail) {
        state.purchase_order_detail.push(detail);
    },

    mDeletePurchaseOrderDetail(state, index) {
        state.purchase_order_detail.splice(index,1);
    },
    mResertPurchaseOrderDetail(state) {
        state.purchase_order_detail = [];
        state.linkages = [];
    },


}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}