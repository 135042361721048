//to handle state
const state = {
    transformation_detail: [],
    transformation_detail_input: [],
    
}

//to handle state
const getters = {}

//to handle actions
const actions = {

    mLoadAddTransformationDetail(context,product) {
        let validate = true;
        let detail = context.state.transformation_detail;
        for (let index = 0; index < detail.length; index++) {
            if (detail[index].id_product == product.id_product) {
                detail[index].quantity = parseFloat(detail[index].quantity) + parseFloat(product.quantity);
                detail[index].capacity = parseFloat(detail[index].capacity);
                detail[index].total_capacity = parseFloat(detail[index].capacity) * parseFloat(detail[index].quantity);
                detail[index].total_final_capacity = parseFloat(detail[index].total_capacity) - parseFloat(detail[index].decrease);

                detail[index].quantity = parseFloat(detail[index].quantity).toFixed(2);
                detail[index].capacity = parseFloat(detail[index].capacity).toFixed(2);
                detail[index].total_capacity = parseFloat(detail[index].total_capacity).toFixed(2);
                detail[index].decrease = parseFloat(detail[index].decrease).toFixed(2);
                detail[index].total_final_capacity = parseFloat(detail[index].total_final_capacity).toFixed(2);
                detail[index].balance_capacity = parseFloat(detail[index].total_final_capacity).toFixed(2);
                validate = false;
                break;
            }
        }
        
        if (validate) {
            context.commit('mAddTransformationDetail',product);
        }
        
    },
    
    mLoadEditTransformationDetailBalance(context,data){
        let detail = context.state.transformation_detail;
        const index = detail.map(item => item.id_product).indexOf(data.id_product);
        let total_capacity = data.total_capacity;
        if (data.type == "Decrease") {
            detail[index].balance_capacity = parseFloat(detail[index].balance_capacity) - parseFloat(total_capacity);
        }
        if (data.type == "Increase") {
            detail[index].balance_capacity = parseFloat(detail[index].balance_capacity) + parseFloat(total_capacity);
        }
        
        detail[index].balance_capacity = parseFloat(detail[index].balance_capacity).toFixed(2);

    },


    mLoadEditTransformationDetail(context,index){
        let detail = context.state.transformation_detail;
        detail[index].quantity = detail[index].quantity.length == 0 ? 0 : parseFloat(detail[index].quantity);
        detail[index].capacity = detail[index].capacity.length == 0 ? 0 : parseFloat(detail[index].capacity);
        detail[index].decrease = detail[index].decrease.length == 0 ? 0 : parseFloat(detail[index].decrease);

        detail[index].total_capacity = parseFloat(detail[index].capacity) * parseFloat(detail[index].quantity);
        detail[index].total_final_capacity = parseFloat(detail[index].total_capacity) - parseFloat(detail[index].decrease);

        detail[index].quantity = parseFloat(detail[index].quantity).toFixed(2);
        detail[index].capacity = parseFloat(detail[index].capacity).toFixed(2);
        detail[index].total_capacity = parseFloat(detail[index].total_capacity).toFixed(2);
        detail[index].decrease = parseFloat(detail[index].decrease).toFixed(2);
        detail[index].total_final_capacity = parseFloat(detail[index].total_final_capacity).toFixed(2);
        detail[index].balance_capacity = parseFloat(detail[index].total_final_capacity).toFixed(2);
    },

    mLoadDeleteTransformationDetail(context,index){
        context.commit('mDeleteTransformationDetail',index);
    },

    mLoadResetTransformationDetail(context){
        context.commit('mResertTransformationDetail');
    },



    mLoadAddTransformationDetailInput(context,product) {
        let validate = true;
        let detail = context.state.transformation_detail_input;
        for (let index = 0; index < detail.length; index++) {
            if (detail[index].id_product_supplies == product.id_product_supplies) {
                validate = false;
                break;
            }
        }
        
        if (validate) {
            context.commit('mAddTransformationDetailInput',product);
        }
        
    },

    mLoadEditTransformationDetailInput(context,index){
        let detail = context.state.transformation_detail_input;
        detail[index].quantity = detail[index].quantity.length == 0 ? 0 : parseFloat(detail[index].quantity);
        detail[index].capacity = detail[index].capacity.length == 0 ? 0 : parseFloat(detail[index].capacity);
        detail[index].total_capacity = parseFloat(detail[index].capacity) * parseFloat(detail[index].quantity);
    
        detail[index].quantity = parseFloat(detail[index].quantity).toFixed(2);
        detail[index].capacity = parseFloat(detail[index].capacity).toFixed(2);
        detail[index].total_capacity = parseFloat(detail[index].total_capacity).toFixed(2);
    },

    mLoadDeleteTransformationDetailInput(context,index){
        let detail = context.state.transformation_detail_input;
        let product_supplies = detail[index];
        let data = {
            id_product : product_supplies.id_product,
            total_capacity : product_supplies.total_capacity,
            type : 'Increase',
          }
          context.dispatch('mLoadEditTransformationDetailBalance',data);
          context.commit('mDeleteTransformationDetailInput',index);
    },

    mLoadResetTransformationDetailInput(context){
        context.commit('mResertTransformationDetailInput');
        let detail = context.state.transformation_detail;
        for (let index = 0; index < detail.length; index++) {
            detail[index].balance_capacity = parseFloat(detail[index].total_final_capacity).toFixed(2);
        }
    },

 
}

//to handle mutations
const mutations = {

    mAddTransformationDetail(state, detail) {
        state.transformation_detail.push(detail);
    },

    mDeleteTransformationDetail(state, index) {
        state.transformation_detail.splice(index,1);
    },
    mResertTransformationDetail(state) {
        state.transformation_detail = [];
        state.transformation_detail_input = [];
    },


    mAddTransformationDetailInput(state, detail) {
        state.transformation_detail_input.push(detail);
    },

    mDeleteTransformationDetailInput(state, index) {
        state.transformation_detail_input.splice(index,1);
    },
    mResertTransformationDetailInput(state) {
        state.transformation_detail_input = [];
    },


 

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}